import { saveEvent } from './EventUtils';

export const ModalTypes = {
    CreateEvent: {
        type: "CreateEventModal",
        heading: "Events",
        minimisable: true,
        onMinimise: (data, remote, storage)=>{
            saveEvent(data.state, remote, storage, true);
        },
        minimisedComponent: "MinimisedEvent"
    },
    ConfirmClose: {
        type: "ConfirmCloseModal"
    },
    ConfirmChange: {
        type: "ConfirmChangeModal"
    },
    GenericConfirm: {
        type: "ConfirmOrCancelModal"
    },
    FileAttachment: {
        type: "FileAttachmentModal"
    },
    EditClientDetails: {
        type: "EditClientDetailsModal"
    },
	EditClientOrAccountDetails:{
		type: "EditDetailsModal"
	},
    TagResearch: {
        type: "TagResearchModal",
        heading: "Research",
        minimisable: true,
        minimisedComponent: "MinimisedResearch"
    },
    CreateGroup: {
        type: "CreateGroupModal"
    },
    CreateUser: {
        type: "CreateUserModal"
    },
    CreateDateOfNote: {
        type: "CreateDateOfNoteModal"
	},
	ApproveUpdateRequest:{
		type: "ApproveUpdateRequestModal"
	},
    ApproveUpdateRequestRequest:{
		type: "ApproveUpdateRequestModalRequest"
	},
	UpdateRequestModal: {
        type: "UpdateRequestModal"
	},
	LeadsInfoModal:{
		type: "LeadsInfoModal"
	},
	DecisionTreeOutcomeModal: {
		type: "DecisionTreeOutcomeModal"
	},
	ConfirmDeleteModal: {
		type: "ConfirmDeleteModal"
	},
	ReviewCompletedModal: {
		type: "ReviewCompletedModal"
	},
	PrescribedPersonModal: {
		type: "PrescribedPersonModal"
	},
	AddAssociatedPersonModal: {
		type: "AddAssociatedPersonModal"
	},
	CreateAccountSimpleModal: {
		type: "CreateAccountSimpleModal"
	},
	LinkRemoteModal: {
		type: "LinkRemoteModal"
    },
    PendingOrdersModal: {
        type: "PendingOrdersModal"
    },
    CreateKouraAccountModal: {
        type: "CreateKouraAccountModal"
    },
    RejectOpenAccountModal: {
        type: "RejectOpenAccountModal"
    },
    CreateFolderModal: {
        type: "CreateFolderModal"
    },
    DeactivatedUserDetailsModal: {
        type: "DeactivatedUserDetailsModal"
    },
    AccountStatusModal : {
        type: "AccountStatusModal"
    },
    BulkSharingModal : {
        type: "BulkSharingModal"
    },
    StockfoxCloseAccountModal : {
        type: "StockfoxCloseAccountModal"
    },
    StockfoxOrderModal : {
        type: "StockfoxOrderModal"
    },
    StockfoxWithdrawalModal : {
        type: "StockfoxWithdrawalModal"
    },
    StockfoxNewAccountModal : {
        type: "StockfoxNewAccountModal"
    },
    OrderHistoryDetailModal : {
        type: "OrderHistoryDetailModal"
    }, 
    AuditTabModal : {
        type: "AuditTabModal"
    },
    CutDownAuditTabModal : {
        type: "CutDownAuditTabModal"
    },
    RestrictionsAndNotesModal : {
        type: "RestrictionsAndNotesModal"
    },
    AccountNoteModal : {
        type: "AccountNoteModal"
    },
    SlateTextInputModal : {
        type: "SlateTextInputModal"
    },
    NewsModal : {
        type: "NewsModal"
    }
};