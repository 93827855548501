import React, { useCallback, useEffect, useMemo } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import "./RichTextEditor.scss";
import {
	Editor,
	Transforms,
	createEditor,
	Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'

import { Button, Toolbar } from './components'
import Tooltip from '../../Popups/Tooltip';
import { withRemote } from '@threeskye/global';

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code',
}

const Icon = ({ children, symbolIcon }) => {
	return <i className={`material-icons${symbolIcon ? " material-symbols-rounded" : ""}`}>{children}</i>
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const ToolBarDivider = () => {
	return (
		<div className="tool-bar-divider" />
	)
}

const RichTextEditor = (props) => {
	const renderElement = useCallback(props => <Element {...props} />, [])
	const renderLeaf = useCallback(props => <Leaf {...props} />, [])
	const editor = useMemo(() => withHistory(withImages(withReact(createEditor()))), [])

	useEffect(() => {
		if (props.controlled && props && props.content && editor && editor.children && props.content !== editor.children) {
			// loop delete all
			editor.children.map(item => {
				Transforms.delete(editor, { at: [0] })
			})

			// reset init 
			editor.children = props.content;
		}
	}, [props.content])

	const onChange = (x) => {
		props.onChange && props.onChange(x);
	}

	const remote = props.remote;

	const readOnly = props.readOnly ? true : false;

	return (
		<Slate editor={editor} value={props.content} onChange={onChange}>
			<div className="slate-editor-inner">
				{!readOnly && <Toolbar>
					<MarkButton format="bold" icon="format_bold" />
					<MarkButton format="italic" icon="format_italic" />
					<MarkButton format="underline" icon="format_underlined" />
					<ToolBarDivider />
					<BlockButton format="heading-one" icon="format_h1" symbolIcon />
					<BlockButton format="heading-two" icon="format_h2" symbolIcon />
					<ToolBarDivider />
					{/* <BlockButton format="block-quote" icon="format_quote" /> */}
					<BlockButton format="numbered-list" icon="format_list_numbered" />
					<BlockButton format="bulleted-list" icon="format_list_bulleted" />
					<ToolBarDivider />
					<BlockButton format="left" icon="format_align_left" />
					<BlockButton format="center" icon="format_align_center" />
					<BlockButton format="right" icon="format_align_right" />
					<BlockButton format="justify" icon="format_align_justify" />
					<ToolBarDivider />
					{!!(props.extraButtons) && props.extraButtons}
					{props.showSignatureButton && <Tooltip label="Email Signature" delay={500}><InsertSignatureButton remote={remote} icon="stylus_note" symbolIcon /></Tooltip>}
				</Toolbar>}
				<div className="editor-wrapper">
					<Editable
						className={props.className || null}
						style={props.style || null}
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						placeholder={props.readOnly ? "" : props.placeholder ? props.placeholder : "Enter some rich text…"}
						spellCheck
						// autoFocus
						readOnly={readOnly}
						onKeyDown={event => {
							for (const hotkey in HOTKEYS) {
								if (isHotkey(hotkey, event)) {
									event.preventDefault()
									const mark = HOTKEYS[hotkey]
									toggleMark(editor, mark)
								}
							}
						}}

					/>
				</div>
			</div>
		</Slate>
	)
}

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	)
	const isList = LIST_TYPES.includes(format)
	Transforms.unwrapNodes(editor, {
		match: n =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	})
	let newProperties;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		}
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		}
	}
	Transforms.setNodes(editor, newProperties)

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

const toggleMark = (editor, format) => {
	if (editor.selection && (format === "highlight" || format === "bold")) {
		const path = editor.selection.anchor.path
		const [node] = Editor.node(editor, path)

		if (node.highlight && format === "highlight") {
			Editor.removeMark(editor, format)
		} else if (node.bold && format === "bold") {
			Editor.removeMark(editor, format)
		} else Editor.addMark(editor, format, true)

	} else {
		const isActive = isMarkActive(editor, format)
		if (isActive) {
			Editor.removeMark(editor, format)
		} else {
			Editor.addMark(editor, format, true)
		}
	}
}

const isBlockActive = (editor, format, blockType = 'type') => {
	const { selection } = editor
	if (!selection) return false

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType] === format,
		})
	)

	return !!match
}

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor)
	return marks ? (marks[format] === true || marks[format] === "true") : false
}

const insertNodes = (editor, nodes) => {
	console.log("Attempting to insert nodes ", nodes)
	if (!nodes) {
		return;
	}
	if (typeof nodes === 'string') {
		nodes = JSON.parse(nodes);
	}
	console.log(nodes)
	Transforms.insertNodes(
		editor,
		nodes,
		{ at: [editor.children.length] }
	)
}

const Element = ({ attributes, children, element }) => {
	const style = { textAlign: element.align, padding: element.padding }
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			)
		case 'bulleted-list':
			return (
				<ul style={style} {...attributes}>
					{children}
				</ul>
			)
		case 'heading-one':
			return (
				<h1 style={style} {...attributes}>
					{children}
				</h1>
			)
		case 'heading-two':
			return (
				<h2 style={style} {...attributes}>
					{children}
				</h2>
			)
		case 'list-item':
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			)
		case 'numbered-list':
			return (
				<ol style={style} {...attributes}>
					{children}
				</ol>
			)
		case 'image':
			return <Image attributes={attributes} children={children} element={element} />
		case 'table':
			return (
				<table>
					<tbody {...attributes}>
						{children}
					</tbody>
				</table>
			)
		case 'table-row':
			return <tr {...attributes}>
				{children}
			</tr>
		case 'table-cell':
			return <td style={style} {...attributes}>
				{children}
			</td>
		default:
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			)
	}
}

const Leaf = ({ attributes, children, leaf }) => {
	let style = {}
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}

	if (leaf.code) {
		children = <code>{children}</code>
	}

	if (leaf.italic) {
		children = <em>{children}</em>
	}

	if (leaf.underline) {
		children = <u>{children}</u>
	}

	if (leaf.highlight) {
		style = { backgroundColor: "yellow", color: "black" }
	}

	return <span style={style} {...attributes}>{children}</span>
}

export const BlockButton = ({ format, icon, symbolIcon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isBlockActive(
				editor,
				format,
				TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
			)}
			onMouseDown={event => {
				event.preventDefault()
				toggleBlock(editor, format)
			}}
		>
			<Icon symbolIcon={symbolIcon}>{icon}</Icon>
		</Button>
	)
}

export const InsertSignatureButton = ({ icon, symbolIcon, remote }) => {
	const editor = useSlate()
	return (
		<Button
			onMouseDown={event => {
				event.preventDefault()
				remote.get("/modules/crm/provider/current-user").then(u => {
					if (u.emailSignature) {
						insertNodes(editor, JSON.parse(u.emailSignature));

					}
				})
			}}
		>
			<Icon symbolIcon={symbolIcon}>{icon}</Icon>
		</Button>
	)
}


export const InsertButton = ({ icon, symbolIcon, nodes }) => {
	const editor = useSlate()
	return (
		<Button
			onMouseDown={event => {
				event.preventDefault()
				insertNodes(editor, nodes)
			}}
		>
			<Icon symbolIcon={symbolIcon}>{icon}</Icon>
		</Button>
	)
}

export const MarkButton = ({ format, icon, symbolIcon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault()
				toggleMark(editor, format)
			}}
		>

			<Icon symbolIcon={symbolIcon}>{icon}</Icon>
		</Button>
	)
}

const withImages = editor => {
	const { insertData, isVoid } = editor

	editor.isVoid = element => {
		return element.type === 'image' ? true : isVoid(element)
	}

	editor.insertData = data => {
		const text = data.getData('text/plain');
		const html = data.getData('text/html');
		const { files } = data
		if (files && files.length > 0) {
			for (const file of files) {
				const reader = new FileReader()
				const [mime] = file.type.split('/')

				if (mime === 'image') {
					reader.addEventListener('load', () => {
						const url = reader.result
						insertImage(editor, url)
					})

					reader.readAsDataURL(file)
				}
			}
		} else if (html && html.startsWith('<img')) {

			const src = html.substring('<img src="'.length, html.length - 2);

			fetch(src).then(function (response) {
				return response.blob()
			}).then(blob => {
				const reader = new FileReader()
				reader.addEventListener('load', () => {
					const url = reader.result
					insertImage(editor, url)
				})

				reader.readAsDataURL(blob);

			})

		} else {
			insertData(data)
		}
	}

	return editor
}

const insertImage = (editor, url) => {
	const text = { text: '' }
	const image = { type: 'image', url, children: [text] }
	Transforms.insertNodes(editor, image)
}

const Image = ({ attributes, children, element }) => {
	// const editor = useSlateStatic()
	// const path = ReactEditor.findPath(editor, element)

	// const selected = useSelected()
	// const focused = useFocused()
	return (
		<div {...attributes}>
			{children}
			<div
				contentEditable={false}
			>
				<img
					src={element.url}
				/>
			</div>
		</div>
	)
}

export default withRemote(RichTextEditor);